/* You can add global styles to this file, and also import other style files */
@import "less/fonts.css";

@import "less/type.less";
@import "less/panes.less";
@import "less/grid.less";

@import "less/tables.less";
@import "less/forms.less";
@import "less/buttons.less";

@import "less/animations.less";
@import "less/colors.less";
@import "less/input-groups.less";
@import "less/dropdowns.less";

@import "less/navs.less";
@import "less/navbar.less";
@import "less/button-groups.less";
@import "less/breadcrumbs.less";
@import "less/panels.less";

@import "less/tab-accordion.less";

@import "less/calendar.less";

@import "less/modals.less";

@import "less/labels.less";
@import "less/layouts.less";
@import "less/sidebar.less";
@import "less/dataicon.less";

@import "less/sidebar-new.less";
@import "less/sidebar-min.less";

@import "less/utilities.less";

@import "less/style.less";
@import "less/validation.less";
@import "less/wizard.less";
@import "less/responsive.less";
@import "less/file-upload.less";
@import "less/misc.less";
@import "less/responsive-align.less";
@import "less/bootstrap-xlgrid.less";

// TEST THESE:

@import "less/main.less";
@import "less/smart-table.less";

@import "app/common/styles/style.less";

@import "less/bootstrap-overrrides";
@import "less/dx-overrrides";
@import "less/mat-overrides";
